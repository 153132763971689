import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";
import { TranslationProvider } from "lib/hooks/useTranslation";
import { createRoot } from "react-dom/client";
import "./index.css";

const App = lazy(() => import("./App"));

// Redirect root Verzeichnis nach sympany.ch
const Routing = () => {
  return (
    <TranslationProvider>
      <Router>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route path="*">
              <App />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </TranslationProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>
);


